import React, {useState} from 'react';
import { Button, Box,  colors, Grid, Stack} from '@mui/material';
import {TextField} from '@mui/material';
import {Container} from '@mui/material';
import COLORS from '../res/colors';
import firebase from '../firebase';
import { db } from '../firebase';
//const db= firebase.firestore();
const settings = {timestampsInSnapshots: true};
  //db.settings(settings);


function Delete_account(){
    const [name, setName] = useState('');
    const[bid,setBid] = useState('');
    const[mobno, setMobno] = useState('');
    const[email,setEmail] = useState('');
    const[reason,setReason] = useState('');
    const[error,setError] = useState('');

    const handleChange = (event) => {
       const value = event.target.value;
       const re = /^[A-Za-z0-9]+$/;
       if (!re.test(value)) {
        setError("Forbidden character: %<>$'\"");
        alert("Special charecters not allowed !");
        
      } else {
        setError("");
        setBid(event.target.value);
       
      }       
       };

    function handleSubmit(event) {
        event.preventDefault();
        //console.log(name,bid,mobno,email,reason); 
        db.collection("AcctDeleteReq").doc(bid).set({"namme":name,"bid":bid,"mobno":mobno,"email":email,"reason":reason});
        alert("Form submitted successfully. Our team will reach out to you. Thankyou");
    }
    return(
        <Container maxWidth="md" sx={{border:2, borderColor:colors.blue}}>
             <h2>Request For Account Deletion</h2>
        <Box  display="flex" flexDirection="column" component="form"  
          height="auto" gap="2" onSubmit={handleSubmit}>
            <Grid container spacing={1}>

                <Grid xs={12} sx={{ mt: 1, mb: 1 }}>
                <TextField required fullWidth label="Name"  
                onChange={e => setName(e.target.value)} value={name}>
                </TextField>
                </Grid>

                <Grid xs={12} sx={{ mt: 1, mb: 1 }}>
                <TextField required fullWidth label="Bandhoo id / User id" type="text"
                  onChange={handleChange} value={bid} helperText={error} error={!!error} >
                  
                  </TextField>
                </Grid>

                <Grid xs={12} sx={{ mt: 1, mb: 1 }}>
                <TextField required fullWidth label="Mobile Number" type="number" 
                  onChange={e => setMobno(e.target.value)} value={mobno}>
                    </TextField>
                </Grid>

                <Grid xs={12} sx={{ mt: 1, mb: 1 }}>
                <TextField required fullWidth label="Email id" type="email"
                  onChange={e => setEmail(e.target.value)} value={email}>
                  </TextField>
                </Grid>

                <Grid xs={12} sx={{ mt: 1, mb: 1 }}> 
                <TextField required fullWidth label="Reason"
                  onChange={e => setReason(e.target.value)} value={reason}></TextField>
                </Grid>

                <Button fullWidth type='submit' variant='contained' 
                sx={{ mt: 3, mb: 2, bgcolor:COLORS.bk_gd, color:COLORS.black}} >Submit</Button>
            
            </Grid>
            <Stack>
            <h4>Important points :</h4>
            <p>1. Either mobile number or email id must be associated with the bandhoo account.</p>
            <p>2. Mobile number and Email id must be in working condition</p>
            <p>3. Account can also be deleted by logging into app</p>
            <h4>Steps for account deletion :</h4>
            <p>1. Verification message will be sent to both Mobile number and Email id</p>
            <p>2. If required, user may be called on mobile for verification purpose</p>
            <p>3. Once user has been verified, associated account will be deleted within 30 working days</p>
            </Stack>
            <Stack>
                <h4>Following data will be deleted :</h4>
                <p>1. User name and Bandhoo id / User id</p>
                <p>2. Associated mobile number or emailid</p>
                <p>3. Details of followers and folowing</p>
                <p>4. Posts</p>
            </Stack>
            <Stack>
                <h4>Followin data may not be deleted :</h4>
                <p>1. Any data required by Law or State</p>
                <p>2. Chat history</p>
                <p>3. Anything that is technically not feasible</p>
                <h4>For any query reach out to us : feedback@bandhoo.in</h4>
            </Stack>
            
        </Box>

        </Container>
    )
}

export default Delete_account;
