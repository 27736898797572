import React, { useState,useEffect, Suspense } from "react";

import { writeBatch, doc, collection, setDoc, getDoc,arrayUnion, updateDoc, increment} from "firebase/firestore"; 
import COLORS from "../res/colors";
import { Avatar, Box, Button, Typography, Grid, Input } from "@mui/material";
import {useAtom} from "jotai"
import { userwa } from "../PostStore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import liked from '../images/liked.png';
import like from '../images/like.png';
import { getDatabase, ref, get,set } from "firebase/database";
import { db } from '../firebase';
import TextField from '@mui/material/TextField';



export default function LikeComment({data,pkey}){
    const dbfirebase = getDatabase();
    const[user,setUser] = useAtom(userwa);//logged in user
    const[likedd,setLikedd] =useState(true);
    const[likescount,setLikescount] = useState(0);
    const[comment,setComment] = useState('');


    
   
   
    useEffect(()=>{
        const subs = onAuthStateChanged(getAuth(),(user)=>{
            if (user) { setUser(user);setLikescount(data.likes); }
             else { } });
        return () => { subs();};
    },[])

   useEffect(()=>{CheckLike()},[]);

     const CheckLike = async()=>{
        if(user){
        const LikesRef = ref(dbfirebase, 'xpress_likes/' + pkey + '/'+ user.uid);
        const abc = await get(LikesRef);
        const bcd =await abc.val();
        if(bcd){setLikedd(true)}else(setLikedd(false));
       return bcd;}
    }

    const whenLiked =async()=>{
        setLikedd(liked);
        setLikescount(data.likes + 1);
        
        set(ref(dbfirebase,'xpress_likes/'+pkey+'/'+user.uid),user.uid);

        //batch write in firestore
        const batch = writeBatch(db);
        const   CollRef = doc(db,'xpress_likedby',pkey);
        const  CollRef1 = doc(db,'xpression',pkey);
        batch.set(CollRef,{likedby:arrayUnion(user.uid)},{merge:true});
        batch.update(CollRef1,{likes:increment(1)});

        //for trending hashtags
        const date = new Date();
        const aajdate = date.getFullYear() + "" + (date.getMonth() + 1) + "" + date.getDate();
        const predate = date.getFullYear() + "" + (date.getMonth() + 1) + "" + (date.getDate() -1);
        const tagRef = doc(db,'hashtags/'+data.langg+'/famous',data.cat);
        //console.log(data.langg,data.cat,aajdate,predate);
        batch.set(tagRef,{
            time:date.getTime(),
            cat :data.cat,
            [aajdate]:increment(1),
            [predate]:increment(1),
            counter:increment(1)

        },{merge:true});
        
        await batch.commit();

    }

    const postComment = async()=>{
        if(!comment){alert('Please comment something !')}
        else{
            const docref = doc(db,'users',user.uid);
            const snap = await getDoc(docref);
            if(snap.exists()){
                const curruser = snap.data();
            const CommRef = doc(collection(db, "Comments/"+pkey+"/comments"));
            
            await setDoc(CommRef,{
                profile : curruser.image,
                buddyid : curruser.buddyid,
                time    : new Date().getTime(),
                msg     : comment,
                name    : curruser.name,
                uid     : curruser.user_uid
            })
            .then((e)=>{
                const  CollRef1 = doc(db,'xpression',pkey);
              
                updateDoc(CollRef1,{
                    comments : increment(1),
                    latest_comment : comment,
                    latest_comment_by : curruser.buddyid
                })

                setComment('');

                 //for trending hashtags
             const date = new Date();
             const aajdate = date.getFullYear() + "" + (date.getMonth() + 1) + "" + date.getDate();
             const predate = date.getFullYear() + "" + (date.getMonth() + 1) + "" + (date.getDate() -1);
             const tagRef = doc(db,'hashtags/'+data.langg+'/famous',data.cat);
             //console.log(data.langg,data.cat,aajdate,predate);
             setDoc(tagRef,{
                        time:date.getTime(),
                         cat :data.cat,
                        [aajdate]:increment(1),
                        [predate]:increment(1),
                        counter:increment(1)

                  },{merge:true});

                  updateRanking(curruser);

            })
        }

        }
        
    }

    const updateRanking = (curruser)=>{
        try{
            const  CollRef1 = doc(db,'xpression',pkey);
        const rank = 1+  (data.views / 100)+(data.likes / 2) + (data.comments / 4) + (data.wacount / 4) + (data.repostno / 4);
        setDoc(CollRef1,{rank:rank},{merge:true});
        }catch(err){}
    }

   
    return(
        <Box sx={{ flexGrow: 1, flexDirection: 'row', overflow: 'hidden'}}>
            {user ? (
             <Grid container spacing={1} >

                 <Grid item   container direction='row'  >
                    <Grid item>
                         <Typography sx={{fontSize:12,height:15, mr:5}} >{'views '+data.views}</Typography>  
                    </Grid>
                    <Grid item>   
           
                      { likedd ? ( <Avatar variant="rounded"  sx={{width:15,height:15}} src={liked} /> )
                     : ( <Avatar variant="rounded"  sx={{width:15,height:15}} src={like} onClick={()=>{whenLiked()}}/>)}

                    </Grid>  
                    <Grid item>
                         <Typography sx={{fontSize:12, height:15,ml:1}}>{data.likes}</Typography> 
                     </Grid>
                </Grid>

                {data.latest_comment_by ? (

                <Grid item container direction='row' spacing={1}>
                    <Grid item>
                        <Typography color={COLORS.blue}  sx={{fontSize:12,height:15}} >{data.latest_comment_by}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography  sx={{fontSize:12,height:15}} >{data.latest_comment}</Typography>
                    </Grid>
                </Grid>
                ) :<></> }

                <Grid item container direction='row' >
                    <Grid item xs >
                        <TextField id="standard-basic" value={comment} InputProps={{ style: { fontSize: 15 }, disableUnderline:true}} onChange={(e)=>{setComment(e.target.value)}} fullWidth color="primary" label="Write comment" size="small"  multiline variant="standard" />
                    </Grid>
                    <Grid item xs={2} justifyContent="flex-end" alignContent='end'>
                        <Button sx={{color:COLORS.black, width:'100%',fontSize:12, textTransform:'capitalize', textAlign:'end'}}
                                onClick={()=>{postComment()}} >Post</Button>
                    </Grid>
                </Grid>
             </Grid>
       
        
        )

        : ( <Typography sx={{fontSize:12,height:15, mr:5}} >{'views '+data.views}</Typography>)
            }
    </Box> 
    )
}