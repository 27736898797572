import React,{useState} from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import logo from '../images/logo.png';
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from "react-router-dom";
import {useAtom} from "jotai"
import { userwa } from "../PostStore";
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
 //https://www.npmjs.com/package/react-phone-number-input

//const auth = getAuth(firebase);
const getCaptha = ()=>{
  window.recaptchaVerifier = new RecaptchaVerifier(auth,'recapcha-container',{
    'size': 'invisible',
    'callback': (response) => {
      //console.log('recapcha ok');
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      //handleSubmit();
    }
  })
}


export default function LoginPage(){
    const[mobno,setMobno] = useState('');
    const[otpsent,setOtpsent] = useState(false);
    const[otp,setOtp] = useState('');
    const navigate = useNavigate('');
    const[user,setUser] = useAtom(userwa);


    const handleSubmit = (e) => {
      if(isValidPhoneNumber(mobno)){
      e.preventDefault();
      getCaptha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth,mobno,appVerifier)
      .then((confirmationResult) =>{window.confirmationResult = confirmationResult; 
        setOtpsent(true);console.log("OTP sent");})
      .catch((error) =>{
      }
    
      )
      }else{alert('please check mobile number !')}
    };

      const Loginnow =(e)=>{
        let confirmationResult = window.confirmationResult;
        confirmationResult.confirm(otp).then((result) => {
          // User signed in successfully.
          const user = result.user;
          setUser(user);
          navigate("/")
          //console.log(user);
          // ...
        }).catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
        });
      }
    
    return(
        <Container component="main" maxWidth="sm" sx ={{fontSize:15}}>
        <CssBaseline />
        <Box sx={{marginTop: 5,pb:2,display: 'flex',flexDirection: 'column',alignItems: 'center',
                  borderRadius:1,borderColor:'red',border:2, }} >
          <Avatar sx={{ m: 1,height:70,width:70 }} src={logo}/>          
          <Typography component="h1" variant="h4"> Log in </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

            {otpsent ? null :
             <PhoneInput international defaultCountry="IN"  placeholder="Enter phone number" value={mobno} 
                onChange={setMobno} 
              />   
            }

            {otpsent ? null :
           
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              <Typography sx ={{fontSize:15}}>Get OTP</Typography> </Button>
              }
            {otpsent ?

            <TextField margin="normal" required fullWidth InputProps={{ style: { fontSize: 15 }}} label="OTP" onChange={(e)=>setOtp(e.target.value)} inputMode="numeric"/>
            : null}
           
            {otpsent ?
            <Button id="submitBtn" type="submit" onClick={Loginnow} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> 
              <Typography sx ={{fontSize:15}}>Log in</Typography></Button>
            : null }
          
        
            <Grid container>
              <Grid item> <Link href="https://play.google.com/store/apps/details?id=xyz.lifeissimple.hibuddy&pli=1" 
                                variant="body2">{"Don't have an account?  Get Bandhoo App to Sign Up"}</Link> </Grid> 
              </Grid>
              <div id="recapcha-container"></div>
          </Box>
        </Box>
       
      </Container>
    )
}