import React, { useState,useEffect } from "react";
import { db } from '../firebase';
import { collection, getDocs, query, where, orderBy, limit} from "firebase/firestore"; 
import {useAtom} from "jotai"
import {userwa,clickedstoryidwa} from "../PostStore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Avatar,Card } from "@mui/material";
import COLORS from "../res/colors";
import { useNavigate } from "react-router-dom";


export default function StoryList(){
    const[livestories,setLivestories] = useState([]);
    const[user,setUser] = useAtom(userwa);
    const[clickedstoryid,setClickedstoryid] = useAtom(clickedstoryidwa);
    const navigate = useNavigate();

    useEffect(()=>{
        const subs = onAuthStateChanged(getAuth(),(user)=>{
            if (user) { setUser(user);liveStory(user);  }
             else {
           }
    
        });
        return () => { subs();};
    },[])

    const liveStory = async (user) =>{
        const date = new Date();
        let q = query(collection(db,"followings/"+user.uid+"/user_followings"),orderBy("storydeletetime",'desc'),
                where("storydeletetime",">",date.getTime()),limit(10));
        const querySnapshot = await getDocs(q);
        let items=querySnapshot.docs.map(doc=>doc.data());
        setLivestories(items); 
           
    }

    const clickStory = (uid)=>{setClickedstoryid(uid);navigate("/Storyon",{state:{clickedstoryid : uid}});}

    return(
        livestories ? (
        <Card sx={{ display:'flex', flexDirection:'row',mb:2}} >          
            {livestories.map((story)=>(
                 <Avatar sx={{m:1,height:75,width:75,border: '1px solid',borderColor:COLORS.bk_gd}} 
                 onClick={()=>clickStory(story.uid)}  src={story.pro_pic}/>
             
            ))}
      
        </Card>) : <></>
    )

}