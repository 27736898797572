import React,{useEffect} from "react";
import Routes from '../Routes'
import Menunew from './MenuNew';
import { Box } from "@mui/material";
import {useAtom} from "jotai"
import { onAuthStateChanged,getAuth } from "firebase/auth";
import { userwa} from "../PostStore";
import COLORS from "../res/colors";

import { ThemeProvider,createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.bk_gd
      }
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 800,
        lg: 1200,
        xl: 1536,
      },
    },
  });

export default function App(){
    const[user,setUser] = useAtom(userwa);

    // onAuthStateChanged(getAuth(), (user) => {
    // if (user) { setUser(user); const uid = user.uid; } else {} });

    useEffect(()=>{
        const subs = onAuthStateChanged(getAuth(),(user)=>{
            if (user) { setUser(user); } 
            else {}
        });
        return () => { subs();};
    },[])

    //this {theme} will be merged with dafault theme. 

    return(
         <ThemeProvider theme={theme}>
             <Box sx={{width:'100%'}}>
             <Menunew/>
             <Routes/>
            </Box>
         </ThemeProvider>

    )
}