import  React, {useState,useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../images/logo.png';
import COLORS from '../res/colors';
import LanguageIcon from '@mui/icons-material/Language';
import {useAtom} from "jotai"
import {langwa,postwa,tagwa,clickeduidwa,clickeduidnamewa,clickeduidbuddyidwa,clickeduidprof_picwa, userwa} from "../PostStore";
import { collection, getDocs, query, where, orderBy, limit,doc,getDoc} from "firebase/firestore"; 
import { db } from '../firebase';
import { useNavigate } from "react-router-dom";
import { getAuth, signOut, onAuthStateChanged} from "firebase/auth";


var yesterday = new Date(Date.now() -86400000);
var predate = yesterday.getFullYear()+''+(yesterday.getMonth()+1)+''+yesterday.getDate();

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const auth = getAuth();

  const [selectedUserMenu, setSelectedUserMenu] = useState(null);
  const [selectedMobMenu, setSelectedMobMenu] = useState(null);
  const[selectedLang,setSelectedtLang] = useState(null);

  const[lang,setLang] = useAtom(langwa);
  const[post,setPost] = useAtom(postwa);
  const [tags,setTags] = useAtom(tagwa);
  const [clickeduid,setClickeduid] = useAtom(clickeduidwa);
  const[clickeduidname,setClickeduidname] = useAtom(clickeduidnamewa);
  const[clickeduidbuddyid,setClickeduidbuddyid] = useAtom(clickeduidbuddyidwa);
  const[clickeduidprof_pic,setClickeduidprof_pic] = useAtom(clickeduidprof_picwa);
  const[user,setUser] = useAtom(userwa);
 

  useEffect(()=>{
    const subs = onAuthStateChanged(getAuth(),(user)=>{
        if (user) { setUser(user);  }
         else {
       }

    });
    return () => { subs();};
},[])


  const handleOpenMobMenu = (event) => {setSelectedMobMenu(event.currentTarget);};
  const handleOpenUserMenu = (event) => { setSelectedUserMenu(event.currentTarget); };
  const handleOpenLangMenu =(e)=>{setSelectedtLang(e.currentTarget);};

  const handleCloseMobMenu = () => { setSelectedMobMenu(null); };
  const handleCloseUserMenu = () => { setSelectedUserMenu(null); };
  const handleCloseLangMenu =()=>{setSelectedtLang(null);};

  const clickedLang =async(langg)=>{
    setSelectedtLang(null);
   //setLang('hi');
   setLang(langg);
   if(user){postOfLoggedUser(user)}
   else{
   const q = query(collection(db,"xpress_nologin"), where("langg","==",langg),orderBy("time",'desc'),limit(60));
   const querySnapshot = await getDocs(q);
   let items=querySnapshot.docs.map(doc=>doc.data());
   setPost(items);
   }

    const q1 = query(collection(db,"hashtags/"+langg+"/famous"),orderBy(predate,'desc'),limit(10));
    const querySnapshot1 = await getDocs(q1);
    let items1=querySnapshot1.docs.map(doc=>doc.data());
    setTags(items1);

  }

  const BackToHome =async()=>{
    setLang('en');
    setClickeduid('');
    setSelectedMobMenu(null);//to close menu after click

    navigate('/Home', {replace:true});

    if(user){postOfLoggedUser(user)}
    else{

    const q = query(collection(db,"xpress_nologin"), where("langg","==",'en'),orderBy("time",'desc'),limit(60));
    const querySnapshot = await getDocs(q);
    let items=querySnapshot.docs.map(doc=>doc.data());
    setPost(items);

    const q1 = query(collection(db,"hashtags/en/famous"),orderBy(predate,'desc'),limit(10));
    const querySnapshot1 = await getDocs(q1);
    let items1=querySnapshot1.docs.map(doc=>doc.data());
    setTags(items1);
    }
  
  }

  const FindUsersMob = ()=>{
    setClickeduid('');
    setSelectedMobMenu(null);//to close menu after click
    navigate('/Top_users');

  }

  const Trendings =()=>{
    setClickeduid('');
    setSelectedMobMenu(null);//to close menu after click
    navigate('/Trendings');
  }

  const postOfLoggedUser = async (user) =>{ 
    let keylist =[];
    const pttostart =0;
    let pttoend =9;
    const docref = doc(db,"users_wall",user.uid);
    let docSnap = await getDoc(docref);
    if(docSnap.exists){
         keylist=Array.from( docSnap.data().postkey);
         keylist.reverse();
         //console.log(keylist.length);
        
        if(keylist.length <10){ pttoend=keylist.length;}
        else{  pttoend=9;}
        let qq = query(collection(db,"xpression"),where("postkey", "in",keylist.slice(pttostart,pttoend)),orderBy("time", "desc"));
        const querySnapshot = await getDocs(qq);
        let items=querySnapshot.docs.map((doc)=>doc.data());
        setPost(items);
    }
}

  const LogInNow =()=>{
   
    setSelectedUserMenu(null);
    navigate("/login");
  }

  const LogOutNow =()=>{
    setSelectedUserMenu(null);
    signOut(auth)
    .then(()=>{setUser(null); alert('You have logged out succesfully.')})
    .catch((error)=>{alert(error)})}


  const MyProfile =async()=>{
    setSelectedUserMenu(null);
    const docref = doc(db,'users',user.uid);
    const snap = await getDoc(docref);
    if(snap.exists()){
      
      postOfUser(user.uid,snap.data().name,snap.data().buddyid,snap.data().image);
    }
   
  }

  const postOfUser = async(uid,name,buddyid,prof_pic)=>{
    setClickeduid(uid);
    setClickeduidname(name);
    setClickeduidbuddyid(buddyid);
    setClickeduidprof_pic(prof_pic);

    const q = query(collection(db,"xpression"), where("uid","==",uid),orderBy("time",'desc'),limit(50));
    const querySnapshot = await getDocs(q);
    let items=querySnapshot.docs.map(doc=>doc.data());
    setPost(items);
    }


  return (
    <AppBar  position="static" sx={{bgcolor:COLORS.bk_gd}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        {/* //xs -extra small -mobile size, md - mediaum - laptop size */}
          <Avatar sx={{display: { xs: 'none', md: 'flex' },mr:1}} src={logo} onClick={BackToHome} />
          <Typography noWrap sx={{fontSize:18, marginLeft:1, color:COLORS.black,display: { xs: 'none', md: 'flex' },mr:2}} > 
           Bandhoo
           </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar"
                aria-haspopup="true" onClick={handleOpenMobMenu} color="inherit" >
                <MenuIcon />
              </IconButton>

               <Menu id="menu-appbar" anchorEl={selectedMobMenu} sx={{ display: { xs: 'block', md: 'none' },}}
                 anchorOrigin={{vertical: 'bottom',horizontal: 'left',}}
                 keepMounted transformOrigin={{vertical: 'top',horizontal: 'left', }}
                 open={Boolean(selectedMobMenu)} onClose={handleCloseMobMenu} >
                   <MenuItem onClick={BackToHome}> <Typography textAlign="center" fontSize={12}>Home</Typography></MenuItem>
                    <MenuItem onClick={Trendings}> <Typography textAlign="center" fontSize={12}>Topics</Typography></MenuItem>
                   <MenuItem onClick={FindUsersMob}> <Typography textAlign="center" fontSize={12}>Users</Typography></MenuItem>
               </Menu>
               <Avatar src={logo} onClick={BackToHome} sx={{ display: { xs: 'flex', md: 'none' }, mr: 1}} />
            
          </Box>


          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button sx={{ml:1, my: 2, color: 'black', display: 'block',fontSize:15,textTransform:'capitalize' }} onClick={BackToHome}>Home</Button>
            {/* <Button sx={{ my: 2, color: 'black', display: 'block',fontSize:15,textTransform:'capitalize' }}>Topics</Button>
            <Button sx={{ my: 2, color: 'black', display: 'block' ,fontSize:15,textTransform:'capitalize'}}>Users</Button> */}
          </Box>

          <Box sx={{ flexGrow: 0,mr:1}}>
          <Tooltip title="Languages"><IconButton onClick={handleOpenLangMenu} sx={{ p: 0}}> <LanguageIcon/></IconButton></Tooltip>
             <Menu sx={{ mt: '35px' }} anchorEl={selectedLang}
              anchorOrigin={{vertical: 'top',horizontal: 'right'}}
              keepMounted transformOrigin={{ vertical: 'top',horizontal: 'right' }}
              open={Boolean(selectedLang)}
              onClose={handleCloseLangMenu}>

              <MenuItem  onClick={()=>{clickedLang('en')}}><Typography textAlign="center" sx={{fontSize:15}}>English </Typography></MenuItem>
              <MenuItem onClick={()=>{clickedLang('hi')}}><Typography textAlign="center" sx={{fontSize:15}}>हिंदी(Hindi)</Typography></MenuItem>
              <MenuItem  onClick={()=>{clickedLang('mr')}}><Typography textAlign="center" sx={{fontSize:15}}>मराठी(Marathi)</Typography> </MenuItem>
              <MenuItem onClick={()=>{clickedLang('bn')}}><Typography textAlign="center" sx={{fontSize:15}}>বাংলা(Bangla)</Typography></MenuItem>
              <MenuItem onClick={()=>{clickedLang('te')}}><Typography textAlign="center" sx={{fontSize:15}}>తెలుగు(Telugu)</Typography> </MenuItem>
              <MenuItem onClick={()=>{clickedLang('ta')}}><Typography textAlign="center" sx={{fontSize:15}}>தமிழ்(Tamil)</Typography></MenuItem>
              <MenuItem onClick={()=>{clickedLang('pa')}}><Typography textAlign="center" sx={{fontSize:15}}>ਪੰਜਾਬੀ(Punjabi)</Typography> </MenuItem>
              <MenuItem onClick={()=>{clickedLang('gu')}}><Typography textAlign="center" sx={{fontSize:15}}>ગુજરતી(Gujarati)</Typography></MenuItem>

            </Menu>
          </Box>


          <Box sx={{ flexGrow: 0}}>
               <Tooltip title="Settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Bandhoo" src={user ? user.photoURL:""} sx={{border: '1px solid',borderColor:COLORS.bk_gd }}/>
                  </IconButton>
                </Tooltip>

              <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={selectedUserMenu}
                anchorOrigin={{vertical: 'top',horizontal: 'right'}}
                keepMounted transformOrigin={{ vertical: 'top',horizontal: 'right' }}
                open={Boolean(selectedUserMenu)}
                onClose={handleCloseUserMenu} >

                  <MenuItem onClick={MyProfile}> <Typography textAlign="center" sx={{fontSize:15}}>Profile</Typography></MenuItem>
                  {user ? 
                  <MenuItem onClick={LogOutNow}> <Typography textAlign="center" sx={{fontSize:15}}>Logout</Typography></MenuItem>
                  :
                  <MenuItem onClick={LogInNow}> <Typography textAlign="center" sx={{fontSize:15}}>Login</Typography></MenuItem>
                  }
            
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;