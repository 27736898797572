import React,{useState,useEffect} from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {useAtom} from "jotai"
import { userwa } from "../PostStore";
import { db } from '../firebase';
import { collection, getDocs, query, where, orderBy, limit} from "firebase/firestore"; 
import  {  Card, Container, Grid} from "@mui/material";
import Stories from 'react-insta-stories';
import '../components/styling.css'


export default function Storyplay(){
    const navigate = useNavigate();
    const[user,setUser] = useAtom(userwa);//for logged in user
    const[loading,setLoading] = useState(false);
   const [stories,setStories] = useState([]);
   const [height,setHeight] = useState(window.innerHeight);
   const stories1 =[];

    useEffect(()=>{
        const subs = onAuthStateChanged(getAuth(),(user)=>{
            if (user) { setUser(user); liveStory(user.uid); }
             else { navigate("/");}

        });
        return () => { subs();};
    },[])

      //to track height of screen. for max height of video.
      useEffect(() => {
        const handleWindowResize = () => {
          setHeight(window.innerHeight);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);


    const liveStory = async (uid) =>{
        const date = new Date();
        let q = query(collection(db,"followings/"+uid+"/user_followings"),orderBy("storydeletetime",'desc'),
                where("storydeletetime",">",date.getTime()),limit(10));
        const querySnapshot = await getDocs(q);
        querySnapshot.docs.map(doc=>loadstories(doc.data().uid));    
       
    }


    async function  loadstories (uid){
      
        let q = query(collection(db,"story"), where("uid","==",uid));
        const querySnapshot = await getDocs(q);
        const item =querySnapshot.docs.map(doc=>doc.data());

       item.forEach((data)=>{
        console.log( (data.media).substring(0,5));
        (data.media).substring(0,5) == 'image' ?

        stories1.push({'url':(data.media).substring(5),'duration':data.duration,
        'header':{'heading':data.bid,'profileImage':data.prof_pic}})
        :
        stories1.push({'url':(data.media).substring(5),'duration':data.duration, 'type':'video',
        'header':{'heading':data.bid, 'profileImage':data.prof_pic}})

        setStories(stories1);
       
       
       })
      
       setLoading(true);
      
       

    }

return(
    <Container  maxWidth="md" sx={{ mt:1, display:'flex', justifyItems:'center',justifySelf:'center',justifyContent:'center'}}>
        <Card sx={{justifyContent:'center', width:'auto', height: height-75}}>
             {!loading ? <p>loading</p> :
                <Stories stories={stories} defaultInterval={3000} width={'100%'} keyboardNavigation
                 onAllStoriesEnd={(s, st) => navigate('/',{ replace: true })}/>
             }
        </Card>   
    </Container>
)
}