import React , {Component} from 'react';
export class Privacy_policy_Ai_Genie extends React.Component {
    constructor(){
        super();
      
      }

    componentDidMount() {}
    render(){
      return(
      <div style={{width:'100%',display:'flex',flexDirection:'column'}}>
      <h2>  Ai Genie Privacy Policy </h2>

      <h4> General guidelines </h4>
      <p>
      When we say “Ai Genie” “our,” “we,” or “us,” we are talking about this ChatGPT based AI application 'Ai Genie' .
             This Privacy Policy (“Privacy Policy”) applies to all of our , services, features, software, and website
              (together, “Services”) unless specified otherwise. When we say “you”,”your”, it means the users who have
               installed/are installing and using our app “Ai Genie”. The following terms of service constitute a binding
                legal agreement between you and us-
      </p>
      <p>
      By providing personal information to us, you consent to our collection, use, and disclosure of your personal 
      information in accordance with this Privacy Policy and any other arrangements that apply between us. We may
       change our Privacy Policy from time to time by publishing changes to it. We request you to 
       check our website periodically to ensure that you are aware of our current Privacy Policy.
      </p>
      <h4>Information we collect</h4>
          <p>It is our endeavour to provide users the best possible experience with minimum information collection. As on date
            we are not collecting any personal information of user like mobile number, email id, name etc.However following information is collected from users when one wishes to use Ai Genie app -</p>
               <p>
              i. Device ID, Device type - For betterment of appliaction
              </p><p>
              ii. Traffic to and from app - For better serving of users
              </p>
              <p>iii. Crash report for betterment of app</p>
              <p>iv. Approximate location for betterment of app</p>

              <h4>Terms and Conditions -</h4>
              <p>
              Thank you for using our apps. We are continually improving and producing more innovative products to
               bring the best values to users.  Please read carefully before using our apps.
              </p>
              <p>By using our app, you agree to be bound by these Terms. If you do not agree to 
                these Terms, do not use our app. If you are using our products on behalf of an organization
                 (such as your employer), you are agreeing to these Terms for that organization, and are indicating
                  that you have the authority to bind that organization to these Terms. In that case, “you” and “your”
                   will refer to that organization.</p>

                   <h4>Paid App</h4>
                   <p>1. Price of app is for life period of 'Ai Genie' app.</p>
                   <p>2. Once app becomes unavailable on the Play store, for any reason, its life will be considerd as over.</p>
                   <p>3. User can take refund of his money within 24 hours of purchase of app. After 24 hours, money can not be
                     refunded in any condition.</p>
                   <p>4. We have right to unpublish or remove app from the Play store any time without any pre information.</p>
                   <p>5. We are not responsile for the usefulness or performance of the app.</p>

                   <h4>Auto Renewing Subscriptions (If app is subscription based):</h4>
                <p>
                 1. Subscribed user has unlimited access to the app 'Ai Genie'</p>
                 <p>2. Unsubscribed users can only use limited features as provided in our app.</p>
                <p>3. Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the
                 current period</p>
                <p>
                 4. Account will be charged for renewal within 24-hours prior to the end of the current period, 
                and identify the cost of the renewal
                </p>
                <p>5. Subscriptions may be managed by the user and auto-renewal may be turned off by going to the 
                users Account Settings after purchase</p>
                <p>6. We reserve the rights to unpublish or close down this app 'Ai Genie' any time without any 
                  pre-information. In such scenario,  subscription of existing users will stand terminated without any
                  refund.
                </p>

                <h4>Replies from app Ai Genie </h4>
                <p>1. 'Ai Genie' is ChatGPT based application. Questions of users are sent to ChatGPT and replies as received
                  from ChatGPT are presented before the user. 
                </p>
                <p>
                  2. This app 'Ai Genie' has nothing to do with the answers. We are not  responsible for the answers. 
                </p>
                <p>3. It is upto the user to like, dislike or use these answers or not.</p>
                <p>4. We are not responsile for inaccuracy, incorrectness, plagiarism, incompleteness or insenstivity of the answers.
                  As the answers are obtained from ChatGPT.
                </p>
                <p>5. If user is not happy with the app, he or she can unsubscribe or uninstall the app anytime. </p>

                <h4>Reach out to us : bandhoo_feedback@yahoo.com or bandhoo.official@yahoo.com </h4>
                   
      </div>
      )

    }
}
export default Privacy_policy_Ai_Genie;