import React, { useState,useEffect } from "react";
import { db } from '../firebase';
import { collection, getDocs, query, where, orderBy, limit} from "firebase/firestore"; 
import COLORS from "../res/colors";
import { Avatar, Card, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import {useAtom} from "jotai"
import {postwa,clickeduidwa,clickeduidbuddyidwa,clickeduidnamewa,clickeduidprof_picwa, clickedhtagwa} from "../PostStore";
import { useNavigate } from "react-router-dom";


export default function UsersNew(){
    const[post,setPost] = useAtom(postwa);
    const[topUsers,setTopUsers] = useState([]);
    const [clickeduid,setClickeduid] = useAtom(clickeduidwa);//uid where clicked
    const[clickeduidname,setClickeduidname] = useAtom(clickeduidnamewa);
    const[clickeduidbuddyid,setClickeduidbuddyid] = useAtom(clickeduidbuddyidwa);
    const[clickeduidprof_pic,setClickeduidprof_pic] = useAtom(clickeduidprof_picwa);
    const[clickedhtag,setClickedhtag] = useAtom(clickedhtagwa);
    const navigate = useNavigate();

    useEffect(()=>{famUsers()},[]);
    
    const famUsers = async (e) =>{
        let q = query(collection(db,"users"),where("active","==",true),where("followers_count",">",10),orderBy("followers_count",'desc'),limit(50));
        const querySnapshot = await getDocs(q);
        let items=querySnapshot.docs.map(doc=>doc.data());
        setTopUsers(items);
    }

    const postOfUser = async(uid,name,buddyid,prof_pic)=>{
        setClickedhtag('');
        setClickeduid(uid);
        setClickeduidname(name);
        setClickeduidbuddyid(buddyid);
        setClickeduidprof_pic(prof_pic);
    
        const q = query(collection(db,"xpression"), where("uid","==",uid),orderBy("time",'desc'),limit(50));
        const querySnapshot = await getDocs(q);
        let items=querySnapshot.docs.map(doc=>doc.data());
        setPost(items);

        navigate('/Home',{ replace: true })//needed when in mobile
        }
    

    return(
        <Card sx={{bgcolor:COLORS.bk_gd}} >
          <p><Typography marginLeft={3} fontSize={15}  color={COLORS.black}>Famous users</Typography></p>     
       
            {topUsers.map((topuser)=>(
              <Card key={topuser.user_uid} sx={{display:'flex', alignItems:'center',verticalAlign:'middle'}}>
              
               <Avatar sx={{m:1,height:50,width:50,border: '1px solid',borderColor:COLORS.bk_gd}} src={topuser.image}  />
               <Button  sx={{display: 'block',fontSize:15,color: 'black', textTransform:'capitalize' }}
               onClick={()=>{postOfUser(topuser.user_uid,topuser.name,topuser.buddyid,topuser.image)}} >{topuser.buddyid}</Button>
             
              </Card>
            ))}
      
        </Card>
    )
}