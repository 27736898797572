import React,{useState, useEffect} from "react";
import { Route, Routes } from "react-router-dom";
//import Home from "./components/Home";
import Privacy_Policy from "./components/Pivacy_Policy";
import Help from "./components/Help";
import Privacy_policy_Ai_Genie from "./components/Privacy_policy_Ai_Geine";
import Delete_account from "./components/Delete_account";
import HomeNew from "./components/HomeNew";
import StoryNew from "./components/StoryNew";
import Privacy_policy_Brain_Gym from "./components/Privacy_policy_Brain_Gym";
import Login from "./components/LoginPage"
import Storyplay from "./components/Storyplay";
import UsersNew from "./components/UsersNew";
import LandingNew from "./components/LandingNew";



export default () =>
{
const [width,setWidth] = useState('800');


useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

const isMobile = width <= 800;

return(

  <Routes>

    <Route path="/" 
     element ={ <HomeNew />}
      /> 
    
    <Route path={"/Delete_account"}
      element={<Delete_account/>}
      />

    <Route path={"/Privacy_policy_Ai_Genie"}
      element ={<Privacy_policy_Ai_Genie />}
      />

    <Route path={"/Privacy_Policy"}
     element ={<Privacy_Policy />}
      />

    <Route path={"/Help"}
      element ={<Help />}
      />

    <Route path={"/Hashtags/:ct?"}
     element ={<HomeNew />}
      />

    <Route path={"/Ids/:usr?"}
     element ={<HomeNew/>}
      />

    <Route path={"/OnePost/:postkey?"}
     element ={<HomeNew />}
      />

    <Route path={"/Story/:storyid?"}
     element ={<StoryNew />}
      />
      
     <Route path="/Home" 
     element ={<HomeNew />}
      />

     <Route path="/Login" element ={<Login/>}/>

     <Route path="/Storyon" element ={<Storyplay/>}/>

    <Route path="/BrainGym_Privacy_Policy" 
     element ={<Privacy_policy_Brain_Gym />}
      />

     <Route path="/Top_users"  element={isMobile ? <UsersNew/> : <HomeNew/>} />

     <Route path="/Trendings" element={isMobile ? <LandingNew/> : <HomeNew/>} />
      
  </Routes>
)
}