import { Stack } from "@mui/material";
import React from "react";
import {Container} from '@mui/material';
export default function Privacy_policy_Brain_Gym(){
    return(
        <Container maxWidth="md">
        <h2>Privacy Policy of Brain Gym App</h2>
        <Stack>
            <h4>General guidelines </h4>
            <p>The Brain Gym app has been developed by Bandhoo Technology</p>
            <p>A. Brain Gym is scientifically designed educational game for children and grown ups.</p>
            <p>B. It has been designed in such a way to increase calculation level, concentration level and memory power.</p>
            <h4>Information we collect -</h4>
            <p>A. We do not collect, store or share the sensitive data of user like name, mobile number, email, phonebook, contacts, SMS, authetication information, 
                any other peronal identifiable information, sex, address, other personal info, financial info, political info, files, audios, videos, photos, health info etc.</p>
            <p>B. Use of Brain Gym does not require authentication. </p>   
            <p>C. However following info may be shared with third party -</p>
                <Stack>
                    <p>1. Location - for marketing / monetization purpose</p>
                    <p>2. Crash logs - for analytic purpose</p>
                    <p>3. Diagnostics - for analytics purpose</p>
                    <p>4. Device IDs - for marketing / monetization purpose</p>
                </Stack>
            <h4 >User Generated Content (UGC)</h4>  
            <p> Users can not generate any kind of content.</p>  

            <h4>Terms and Reference -</h4>
            <p>
            When we say “Brain Gym” “our,” “we,” or “us,” we’re talking about this Brain Gym app.
             This Privacy Policy (“Privacy Policy”) applies to all of our , services, features, software, and website
              (together, “Services”) unless specified otherwise. When we say “you”,”your”, it means the users who have
               installed/are installing and using our app “Brain Gym”. The following terms of service constitute a binding
                legal agreement between you and Hibuddy- 
            </p>
            <p>1. By accessing, downloading, installing the Application, You represent to Brain Gym that You are competent
                 to enter into a contract (i.e. You a major according to applicable law, of sound mind and not 
                 disqualified from entering into a contract under the applicable law) and You have read this Agreement, 
                 understand it and agree to be bound by its Terms and condition. 2. The Brain Gym Application and related 
                 services are currently made available to You by Bandhoo technoogy free of any charge.</p>

            <h4>Disclaimers</h4>
                <p> !. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, 
                YOUR USE OF THE APPLICATION IS AT YOUR SOLE RISK AND DISCRETION. THE APPLICATION IS LICENSED AS-IS, WITH ALL 
                FAULTS, AND AS AVAILABLE. YOU BEAR THE RISK OF USING IT. Bandhoo MAKES NO WARRANTY AGAINST INTERFERENCE OF YOUR 
                ENJOYMENT OF THE APPLICATION AND RELATED SERVICES; FOR LOSSES OR DAMAGES INCLUDING ANY PERSONAL INJURY ARISING 
                FROM OR IN ANY WAY RELATED TO YOUR ACCESS OR USE OF THE APPLICATION, USE OF ANY CONTENT POSTED, TRANSMITTED, OR
                OTHERWISE MADE AVAILABLE VIA THE APPLICATION OR FOLLOWING A FAILURE, SUSPENSION OR WITHDRAWAL OF ALL OR PART OF
                THE APPLICATION AT ANY TIME; ANY BUGS, VIRUSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR
                APPLICATION OR RELATED SERVICES, THAT THE APPLICATION WILL BE FUNCTIONAL, UNINTERRUPTED, ERROR-FREE OR 
                BUG-FREE OR MEET YOUR REQUIREMENTS; REGARDING THE SECURITY, RELIABILITY OR TIMELINESS OF THE APPLICATION;
                THAT ANY ERRORS, BUGS OR FAILURES IN THE APPLICATION WILL BE CORRECTED. ACCORDINGLY, YOU EXPRESSLY AGREE 
                THAT USE OF APPLICATION AND RELATED SERVICES IS PURELY VOLUNTARY ON YOUR PART, AT YOUR OWN RISK. 
            </p>  

            <p> 2. We may in our sole discretion amend this Agreement from time to time without any prior notice. 
                This Agreement commences on the date You accept the Terms of this Agreement. Bandhoo may terminate or 
                suspend use of the Application or the Site at any time in its discretion, without notice to You. Upon any 
                termination for any reason, Bandhoo shall have no liability to You and no further obligations under this 
                Agreement.  You acknowledge that You have read this Agreement, understand it and agree to be bound by 
                its terms and conditions.  This Agreement shall be governed by the laws of India. You irrevocably consent
                to the exclusive jurisdiction of courts in New Delhi, India for all disputes arising out of or relating to 
                this Agreement. </p>  

            <h4>Reach out to us </h4>    
            <p>For any query please contact : feedback@bandhoo.in</p>
            <p>Call +919664293098</p>
        </Stack>
        </Container>
    )
}