
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'jotai';
import App from './components/App'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
 
    <Router>
      
      <App />
     
    </Router>,
   
   
  );


serviceWorker.unregister();
