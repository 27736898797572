import React from 'react';
import { Card, Typography,Box,Stack } from "@mui/material";
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from "@mui/icons-material/Apple"
import COLORS from '../res/colors';

export default function GetBandhooAppMobile(){
    return(
  <Card  sx={{mb:3}} >
        <Box sx={{margin:2}}>
            <Typography fontSize={15}>Get Bandhoo app to enjoy all features</Typography>
            <Stack direction="row" spacing={3}>
                <AndroidIcon onClick={()=>{window.open("https://play.google.com/store/apps/details?id=xyz.lifeissimple.hibuddy&pli=1",'_blank')}} sx={{ fontSize: 40, color:COLORS.bk_gd }}/>
               <AppleIcon sx={{ fontSize: 40, color:COLORS.bk_gd  }}/>
           </Stack>
       </Box>
   </Card>
    )
}