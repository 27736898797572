//import  firebase from 'firebase';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";


var config = {
    apiKey: process.env.REACT_APP_firebase_apiKey,
    authDomain: process.env.REACT_APP_firebase_authDomain,
    databaseURL: process.env.REACT_APP_firebase_databaseURL,
    projectId:'bandhoo-bac6f',
    storageBucket:'process.env.REACT_APP_firebase_storageBucket',
    messagingSenderId: 'process.env.REACT_APP_firebase_messagingSenderId'
  };
  const firebase = initializeApp(config);
 // firebase.initializeApp(config);
  const db =getFirestore(firebase);
  const auth = getAuth(firebase);
  export {db};
  export {auth};  
  //export {firebase};
  export default firebase;