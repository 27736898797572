import React, { useState,useEffect } from 'react';
import firebase from '../firebase';
import { useParams } from 'react-router-dom';
import COLORS from '../res/colors';
import  { Avatar, Card, CardActions, CardContent, CardHeader, CardMedia, Container, Typography, Stack, Box} from "@mui/material";
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from "@mui/icons-material/Apple"
import { db } from '../firebase';
import { collection, getDocs, query, where, orderBy, limit} from "firebase/firestore"; 


export default function StoryNew(){
    const [storyPost,setStorypost] = useState([]);
    let {storyid} = useParams();

    const fetchStories =async()=>{
        const q = query(collection(db,"story"), orderBy("time",'desc'),limit(5));
        const querySnapshot = await getDocs(q);
        let items=querySnapshot.docs.map(doc=>doc.data());
        setStorypost(items);

    }

    const fetchStory =async()=>{
        const q = query(collection(db,"story"), where("storyid","==",storyid));
        const querySnapshot = await getDocs(q);
        let items=querySnapshot.docs.map(doc=>doc.data());
        setStorypost(items);
         //since  user is not logged in. So started below calleable function ....increaseViews
         var increaseViewsStory = firebase.functions().httpsCallable('increaseViewsStory');
         increaseViewsStory({key: storyid});
       
    }


    storyid ? useEffect(()=>{fetchStory()},[])
            : useEffect(()=>{fetchStories();},[]) ;


    const calculateTime=(time)=>{
        var diff= Math.abs(new Date(time).getTime() - new Date().getTime());
        var ddiff;
        var hdiff;
        var mdiff;
        ddiff=Math.floor(diff/(1000*60*60*24));
        diff -= ddiff*1000*60*60*24;
     
        hdiff=Math.floor(diff/(1000*60*60));
        diff -= hdiff*1000*60*60;
     
        mdiff=Math.floor(diff/(1000*60));
        diff -= mdiff*1000*60;
        if(ddiff >= 1)
        {
        return String(ddiff)+'d '+String(hdiff)+'h '+String(mdiff)+'m';
        }
        else if (hdiff >=1){ return String(hdiff)+'h '+String(mdiff)+'m';}
     
        else {return String(mdiff)+'m';}    
       }


    return(
        <>

        <Container maxWidth="md">
        {
             <Card container sx={{margin:1}} >
                 <Box sx={{margin:2}}>
                     <p><Typography fontSize={15}>Get Bandhoo app to enjoy all features</Typography></p>
                     <Stack direction="row" spacing={3}>
                         <AndroidIcon onClick={()=>{window.open("https://play.google.com/store/apps/details?id=xyz.lifeissimple.hibuddy&pli=1",'_blank')}} sx={{ fontSize: 40, color:COLORS.bk_gd }}/>
                        <AppleIcon sx={{ fontSize: 40, color:COLORS.bk_gd  }}/>
                    </Stack>
                </Box>
            </Card>
        }
        </Container>
        
        <Container maxWidth="md">
            {
                 storyPost.map((data) =>(
                    <Card sx={{margin:1}}>
                        <CardHeader 
                             avatar={
                                <Avatar src={data.prof_pic} sx={{width:60,height:60}} id={data.id}/>
                                    }
                             title={<Typography fontSize={15} color={COLORS.blue} id={data.id}>{data.bid}</Typography>}
                             subheader={calculateTime(data.time)}
                        />

                        <CardContent>
                            <Typography fontSize={15} id={data.id}>
                                    {data.msg}
                            </Typography>
                         </CardContent>

                         <p id={data.id}>
                           {(data.media).startsWith('video') ?
                            <CardMedia key={data.id} component="video" src={(data.media).substring(5)} controls />
                             : (data.media).startsWith('image') ?
                             <CardMedia key={data.id} component="img" src={(data.media).substring(5)}/>
                             :null}
                          </p>

                        <CardActions>
                            <p id={data.id}>{'views '+data.views}</p>
                        </CardActions>
                    </Card>
                   ))                  

            }
           
        </Container>
        </>
        
    )

}