import {atom} from 'jotai'
export const postwa = atom([]);
export const uidwa = atom('');
export const langwa = atom('en');
export const tagwa = atom([]);
export const userwa = atom(null);
export const clickeduidwa = atom('');
export const clickeduidnamewa = atom('');
export const clickeduidbuddyidwa = atom('');
export const clickeduidprof_picwa = atom('');
export const clickedstoryidwa = atom('');
export const clickedhtagwa = atom('');

