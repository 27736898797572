import React, { useEffect } from "react";
import {useState} from 'react';
import MobileScreenNew from './MobileScreenNew';
import PcscreenNew from './PcscreenNew';

export default function HomeNew(){
   
    const [width,setWidth] = useState(window.innerWidth);


                useEffect(() => {
                    const handleWindowResize = () => {
                      setWidth(window.innerWidth);
                    };
                
                    window.addEventListener('resize', handleWindowResize);
                
                    return () => {
                      window.removeEventListener('resize', handleWindowResize);
                    };
                  }, []);

    const isMobile = width <= 800;
   

    if(isMobile){return(<MobileScreenNew/>)}
    else {return(<PcscreenNew/>)}

}