import React , {Component} from 'react';
export class Help extends React.Component {
  
    constructor(){
      super();
    
    }
    componentDidMount(){}
    render(){
        return(
            <div style={{width:'100%',display:'flex',flexDirection:'column', margin:10}}>
            <p>                
             Bandhoo - Indian social app
            </p>
            <p>                
              How to get Best out of Bandhoo -
            </p>
            <p>
                 Xpressions - 1. One can post video, images, GIFs, messages on the Bandhoo. 
                2. One can select any language. Then he/she will see all posts in that perticular language.
                3. One can like, share, repost, download or comment on any post.
                4. Any post can be shared to Whatsapp, just with one click.
                5. One can Follow any user of his choice. If A follows B, then A will see all posts of B. 
            </p>
            <p>
                 Chatting - 1. One can chat with any user.
                2. One can block any user, if he doen not want to chat with that user.
                3. One can hide his/her mobile no. Then no one will be able to see his/her mobile no.
                4. In chatting, one can share videos, images, messages OR office files.
                5. One can also create groups and share post in group.
            </p>
            <p>
                Games - One can play many games which are designed to enhance mental and calculation capability of the user.
            </p>
            <p>
                 Account - 1. Account can be created either by mobile no OR valid email id. 
                2. One can sign out OR delete his account any time.
            </p>
            <p>For any query plz contect -</p>
                <p>1.feedback@bandhoo.in</p>
               <p> 2. bandhoo_feedback@yahoo.com </p>               
                <p>3. bandhoo.official@yahoo.com</p>
                <p>4. @bandh18 on Bandhoo</p>                
               <p> 5. +919664293098</p>
            
            </div>
            )}
}
export default Help;