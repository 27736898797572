import { Stack, Typography,Button, Card } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import COLORS from "../res/colors";
import {langwa, postwa, tagwa,clickeduidwa,userwa,clickedhtagwa} from "../PostStore";
import {useAtom} from "jotai"//using lib jotai for state management
import { db } from '../firebase';
import { collection, getDocs, query, where, orderBy, limit} from "firebase/firestore"; 
import { useNavigate } from "react-router-dom";


var yesterday = new Date(Date.now() -86400000);
var predate = yesterday.getFullYear()+''+(yesterday.getMonth()+1)+''+yesterday.getDate();

export default function LandingNew(){
    const[post,setPost] = useAtom(postwa);
    const [clickeduid,setClickeduid] = useAtom(clickeduidwa);
    const[lang,setLang] = useAtom(langwa);
    const [tags,setTags] = useAtom(tagwa);
    const[user,setUser] = useAtom(userwa);//logged in user
    const[clickedhtag,setClickedhtag] = useAtom(clickedhtagwa);
    const navigate = useNavigate();
    

    const fetchTags =async (e)=>{
        //console.log(lang);
        const q = query(collection(db,"hashtags/"+lang+"/famous"),orderBy(predate,'desc'),limit(10));
        const querySnapshot = await getDocs(q);
        let items=querySnapshot.docs.map(doc=>doc.data());
        setTags(items);
    }

    const clickedTag =async(tagClicked)=>{
        setClickeduid('');
        setClickedhtag(tagClicked);
        let q ='';
        !user ?  q = query(collection(db,"xpress_nologin"),where("htags","array-contains",tagClicked),orderBy("time",'desc'),limit(10))
        : q = query(collection(db,"xpression"),where("htags","array-contains",tagClicked),orderBy("time",'desc'),limit(10));
        const querySnapshot = await getDocs(q);
        let items=querySnapshot.docs.map(doc=>doc.data());
        setPost(items);

       // navigate('/Home',{ replace: true })//needed when in mobile
        navigate("/Hashtags/"+tagClicked);
      
         }

    useEffect(() =>{fetchTags();},[]);

    return(
       <>
       {
        <Card sx={{bgcolor:COLORS.bk_gd}} >
            
            <Typography marginLeft={3} fontSize={15}  color={COLORS.black}>Trending topics </Typography>
        
             <Card>{
                tags.map((data)=>(
                    <Stack key={data.cat} spacing={2} marginLeft={3}>
                        <Button key={data.postkey} sx={{textAlign:'start', justifyContent:'flex-start', textTransform:'capitalize',fontSize:15,
                         color:COLORS.blue}} onClick={()=>{clickedTag(data.cat)}}> {data.cat} </Button>
                    </Stack>
                        )
                    )}
             </Card>
        </Card>
       }
       </>
    )
}